import { mapGetters, mapActions } from 'vuex'
import { metaInfoBuilder, kebabToSnake } from '../utilities'
import { PAGES, BLOCKS } from '../content-types';
const type = PAGES.BRAND

export const BrandComponent = {
  name: 'BrandComponent',
  computed: {
    ...mapGetters('prismic', ['getPage', 'getBlock', 'getAlternateLanguages']),
    ...mapGetters('konami', ['code', 'overlay']),
    brand() {
      return this.page ? {
        main: this.page.main,
        content: this.page.content
          .map(component => {
            const block = this.centralizedBlocks.find(b => component.component === kebabToSnake(b))
            return this.getBlock(block) || component
          }),
          konami: this.page.konami
      } : { main: null, content: [], konami: null }
    }
  },
  methods: {
    ...mapActions('prismic', ['setPage', 'setBlock']),
    ...mapActions('konami', ['setCode', 'setOverlay'])
  },
  async serverPrefetch() {
    // Set the centralized blocks data
    const blocks = this.centralizedBlocks
      .map(uid => this.setBlock(uid))
    // Set the prismic cms type (page) data
      const page = this.setPage({ uid: this.$route.params.uid, type })
      await Promise
      .all([...blocks, page])
      .then(() => {
        // Get the page from the vuex state (action setPage -> commit VuexState -> getters getPage to retrieve the slice of state)
        const page = this.getPage({ uid: this.$route.params.uid, type })
        // konami code and overlay management
        this.setCode({ code: page && page.konami ? page.konami.code : null })
        this.setOverlay({ overlay: !!(page && page.konami && page.konami.code) })
        this.page = page
      })
  },
  created() {
    const blocks = this.centralizedBlocks
      .map(uid => this.setBlock(uid))
    const page = this.setPage({ uid: this.$route.params.uid, type })
    Promise
      .all([...blocks, page])
      .then(() => {
        const page = this.getPage({ uid: this.$route.params.uid, type })
        this.setCode({ code: page && page.konami ? page.konami.code : null })
        this.setOverlay({ overlay: !!(page && page.konami && page.konami.code) })
        this.page = page
      })

  },
  metaInfo() {
    const page = this.page
    return page ? metaInfoBuilder(page, this.$route, this.getAlternateLanguages({type, uid: this.$route.params.uid})) : {}
  },
  data() {
    return {
      page: null,
      centralizedBlocks: [
        BLOCKS.TAGLINE_HORIZONTAL_AUTO_SCROLL,
        BLOCKS.TAGLINE_HORIZONTAL_AUTO_SCROLL_PANTONE,
        BLOCKS.COLLECTIONS_THUMBS,
      ]
    }
  }
}
